/**
 * Created by razial on 25.03.2025.
 */

var GardenLocation = function (locationId) {
    BaseLocation.call(this, locationId);

    this.slot = GardenLocation.CalcSlot(locationId);

    this.families = Families.listExpeditionCodes("main");

    this.meta = bundles["location_" + locationId].meta;

    this.adapter = new GardenLocationAdapter(this.slot);

    this.stars = this.adapter.load("stars") || 0;
    this.stage = this.adapter.load("stage") || 0;

    this.totalStages = 20;

    this.mode = GardenLocation.MODE_GAME;
};

GardenLocation.prototype = Object.create(BaseLocation.prototype);
GardenLocation.prototype.constructor = GardenLocation;

GardenLocation.MODE_GAME = 0;
GardenLocation.MODE_META = 1;

GardenLocation.CHANGE_ANIMATION_DURATION = 150;

GardenLocation.prototype.changeMode = function (mode, f) {
    this.mode = mode;

    this.trigger("changeMode");

    cleverapps.timeouts.setTimeout(f, GardenLocation.CHANGE_ANIMATION_DURATION);
};

GardenLocation.prototype.nextStage = function () {
    this.stage++;

    this.adapter.save("stage", this.stage);

    this.trigger("changeStage", this.stage);
};

GardenLocation.prototype.isCompleted = function () {
    return this.stars >= GardenLocation.STARS_GOAL;
};

GardenLocation.prototype.addStars = function (stars, silent) {
    this.stars += stars;
    this.adapter.save("stars", this.stars);

    if (!silent) {
        this.onChangeStars(stars);
    }
};

GardenLocation.prototype.onChangeStars = function () {
    this.trigger("changeStars", this.stars);
};

GardenLocation.prototype.withWands = function () {

};

GardenLocation.prototype.getPendingProgress = function () {
    return 0;
};

GardenLocation.prototype.getProgress = function () {
    return this.stage / this.totalStages * 100;
};

GardenLocation.prototype.moveNextLocation = function () {
    cleverapps.focusManager.display({
        focus: "gardenMoveNextLocation",
        action: function (f) {
            cleverapps.meta.moveNextLocation();
            cleverapps.meta.gotoLocationScene(f);
            f();
        }
    });
};

GardenLocation.GetMainScene = function () {
    return Merge2Scene;
};

GardenLocation.CalcSlot = function (locationId) {
    var slot = [Meta.SLOT_MAIN, Meta.EXPEDITION_SLOT1, Meta.EXPEDITION_SLOT2][locationId % 3];

    return slot !== undefined ? slot : Meta.EXPEDITION_SLOT3;
};

GardenLocation.STARS_GOAL = 1000;
