/**
 * Created by vladislav on 01.02.2022
 */

cleverapps.overrideColors(cleverapps.styles.COLORS, {
    COLOR_WINDOW_TEXT: new cc.Color(20, 74, 131, 255),
    PURPLE: new cc.Color(61, 29, 125, 255),
    COLOR_FORCE_MESSAGE_TEXT: new cc.Color(141, 74, 56),
    BLACK_STROKE: new cc.Color(0, 0, 0, 110),
    GREEN: new cc.Color(119, 229, 0, 46),
    COLOR_RED: new cc.Color(255, 80, 94, 46),
    DARK_BLUE_TEXT_COLOR: new cc.Color(20, 74, 131, 110),
    COLOR_BONUS: new cc.Color(215, 169, 255, 255),
    STICKERS_COLLECTION_COLOR: new cc.Color(225, 229, 119, 1),
    BARREL_ADS_ITEM_TITLE_COLOR: new cc.Color(49, 108, 150, 255),
    BROWN_BUTTON_COLOR: new cc.Color(128, 45, 23, 255),
    STICKER_LABEL_COLOR: new cc.Color(75, 111, 128, 255),
    DARK_TEXT_COLOR: new cc.Color(121, 75, 57, 255)
});

cleverapps.overrideStyles(cleverapps.styles.DECORATORS, {
    TILE_SHOP_WINDOW_TIMER_STROKE: {
        color: new cc.Color(196, 29, 58, 255),
        size: 1
    },

    TILE_SHOP_BADGE_STROKE: {
        color: new cc.Color(243, 78, 36, 255),
        size: 3
    },

    IMAGE_FONT_STROKE: {
        color: cleverapps.styles.COLORS.BLACK_STROKE,
        size: 2
    },

    IMAGE_FONT_STROKE_THIN: {
        color: cleverapps.styles.COLORS.BLACK_STROKE,
        size: 1
    },

    TITLE_TEXT_SHADOW: {
        color: cleverapps.styles.COLORS.BLACK,
        direction: cc.size(0, -2),
        blur: 6
    },

    WHITE_TEXT_SHADOW: {
        color: new cc.Color(0, 0, 0, 250),
        direction: cc.size(0, -2),
        blur: 6
    },

    RED_STROKE: {
        color: cleverapps.styles.COLORS.COLOR_RED,
        size: 2
    },

    DIALOGUE_PERSON_TITLE_TEXT_STROKE: {
        color: cleverapps.styles.COLORS.BLACK_STROKE,
        size: 1
    },

    DIALOGUE_PERSON_TITLE_TEXT_SHADOW: {
        color: cleverapps.styles.COLORS.BLACK,
        direction: cc.size(0, 0),
        blur: 3
    },

    TRANSPARENT_BLACK_3PX: {
        color: cleverapps.styles.COLORS.BLACK_STROKE,
        size: 3
    },

    MISSION_STROKE: {
        color: new cc.Color(255, 90, 0, 255),
        size: 3
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    LOADING_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE
    },

    SHOP_WINDOW_TILE_TITLE: {
        size: 64,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.RED_STROKE
    },

    TILE_SHOP_COINS_OFFER: {
        size: 64,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.RED_STROKE
    },

    UNITSSHOPTILE_AMOUNT: {
        size: 50
    },

    BUTTON_BROWN_TEXT: {
        size: 36,
        color: cleverapps.styles.COLORS.BROWN_BUTTON_COLOR
    },

    SHOP_WINDOW_SMALL_TILE_TITLE: {
        size: 50,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.TRANSPARENT_BLACK_2PX
    },

    PACKWINDOW_TEXT: {
        name: "default",
        size: 64,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.RED_STROKE
    },

    TILE_SHOP_WINDOW_BADGE_TIMER: {
        size: 22,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.TILE_SHOP_WINDOW_TIMER_STROKE
    },

    UNITSSHOPTILE_NAME: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.TRANSPARENT_BLACK_2PX
    },

    UNITSSHOP_APPEAR_IN: {
        size: 54,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.TRANSPARENT_BLACK_2PX
    },

    TOOLBAR_ITEM_TIMER_TEXT: {
        size: 27
    },

    SHOPTILE_LEFT: {
        size: 32,
        color: cleverapps.styles.COLORS.DARK_BLUE_TEXT_COLOR
    },

    UNITSSHOP_CART_AMOUNT: {
        name: "nostroke",
        size: 40,
        color: cleverapps.styles.COLORS.DARK_BLUE_TEXT_COLOR,
        stroke: undefined,
        shadow: undefined
    },

    MENUBAR_TIMER_TEXT: {
        size: 28,
        stroke: cleverapps.styles.DECORATORS.TRANSPARENT_BLACK_2PX
    },

    RATE_WINDOW_TEXT: {
        size: 60,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.TRANSPARENT_BLACK_3PX
    },

    TILE_REWARDS: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },
    PRODUCT_TILE_LIMIT_TEXT: {
        size: 32,
        color: cleverapps.styles.COLORS.DARK_BLUE_TEXT_COLOR,
        stroke: undefined,
        shadow: undefined
    },
    TIMER_TEXT: {
        size: 42,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    ORDERS_PLACEHOLDER_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE
    },

    ORDERS_WINDOW_TITLE: {
        size: 70,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT
    },

    LOADING_ANIMATION_TEXT: {
        size: 54,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.TRANSPARENT_BLACK_2PX
    },

    TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT,
        name: "default"
    },

    WINDOW_SMALL_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.DARK_BLUE_TEXT_COLOR
    },

    TOURNAMENT_PLAYER_TEXT: {
        name: "nostroke",
        size: 30,
        color: cleverapps.styles.COLORS.BLACK,
        stroke: undefined,
        shadow: undefined
    },

    SIDEBAR_ICON_TEXT: {
        name: "default",
        size: 40,
        color: cleverapps.styles.COLORS.WHITE
    },

    WINDOW_TEXT: {
        size: 43,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT,
        stroke: undefined,
        shadow: undefined
    },

    SAME_ITEMS_TEXT: {
        size: 28,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT
    },

    ORDERICON_AMOUNT_TEXT: {
        size: 58,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT
    },

    INGREDIENT_ORDER_ICON_TEXT: {
        size: 58
    },

    DARK_TEXT: {
        size: 45,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT
    },

    BUYINGREDIENTSWINDOW_TITLE: {
        size: 55,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT,
        stroke: undefined,
        shadow: undefined
    },

    UNITICON_TITLE: {
        size: 36,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT
    },

    UNITICON_LEVEL: {
        size: 36,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR
    },

    PROMOTION_TEXT: {
        size: 30,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT
    },

    EXTRA_WINDOW_TEXT: {
        size: 43,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT
    },

    BUTTON_TEXT: {
        size: 58,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.TRANSPARENT_BLACK_2PX
    },

    SMALL_TEXT: {
        size: 30,
        color: cleverapps.styles.COLORS.WHITE,
        shadow: cleverapps.styles.DECORATORS.WHITE_TEXT_SHADOW
    },

    VERY_BIG_TEXT: {
        size: 100,
        color: cleverapps.styles.COLORS.COLOR_VICTORY,
        shadow: cleverapps.styles.DECORATORS.WHITE_TEXT_SHADOW
    },

    WINDOW_BIG_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT
    },

    WINDOW_TITLE_TEXT: {
        size: 65,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.TRANSPARENT_BLACK_3PX
    },

    NOBG_WINDOW_TITLE_TEXT: {
        size: 65,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.TRANSPARENT_BLACK_3PX
    },

    NOBG_WINDOW_TITLE_VERTICAL_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.TRANSPARENT_BLACK_3PX
    },

    FRIEND_REQUEST_TEXT: {
        size: 35,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.TRANSPARENT_BLACK_3PX
    },

    MENUBAR_TEXT: {
        size: 42
    },

    PASS_TICKET_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT
    },

    PASS_PROGRESS_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.WHITE,
        shadow: cleverapps.styles.DECORATORS.WHITE_TEXT_SHADOW
    },
    DAILY_TASKS_BAR: {
        size: 45
    },
    DAILY_TASK_PROGRESS_FONT: {
        size: 38
    },
    DAILY_CUP_TITLE_TEXT: {
        size: 80,
        color: cleverapps.styles.COLORS.WHITE,
        shadow: cleverapps.styles.DECORATORS.TITLE_TEXT_SHADOW
    },
    DAILY_TASKS_TIMER_TEXT: {
        size: 43,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT,
        stroke: undefined,
        shadow: undefined
    },
    SMALL_TEXT_ERROR: {
        size: 32,
        color: cleverapps.styles.COLORS.DARK_RED,
        stroke: undefined
    },
    LOSE_WINDOW_TEXT: {
        color: cleverapps.styles.COLORS.WHITE,
        size: 60
    },
    LOSE_WINDOW_TITLE_TEXT: {
        color: cleverapps.styles.COLORS.WHITE,
        size: 70
    },

    TILE_SHOP_OFFER_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE
    },
    REWARDS_LIST_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT
    },
    BREAK_AMOUNT_TEXT: {
        name: "default",
        size: 40,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT
    },
    FORCE_MESSAGE_TEXT: {
        size: 52,
        color: cleverapps.styles.COLORS.COLOR_FORCE_MESSAGE_TEXT
    },
    SHOP_WINDOW_LIVES_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },
    SUBSCRIPTION_PRIZE_SUBTITLE: {
        size: 60
    },
    SUBSCRIPTION_MAIN_TEXT: {
        size: 48,
        color: cleverapps.styles.COLORS.WHITE
    },
    SUBSCRIPTION_BUTTON_TITLE: {
        size: 36,
        color: cleverapps.styles.COLORS.WHITE
    },
    TERMS_TEXT: {
        size: 32,
        color: cleverapps.styles.COLORS.WHITE
    },
    TERMS_LINK_TEXT: {
        size: 32,
        color: new cc.Color(200, 200, 200, 200)
    },
    BADGE_TEXT: {
        size: 33,
        color: cleverapps.styles.COLORS.WHITE
    },
    KNOCKOUT_WINDOW_COUNTDOWN_TEXT: {
        size: 70,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT
    },

    DIALOGUE_PERSON_TITLE_TEXT: {
        size: 52,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.DIALOGUE_PERSON_TITLE_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.DIALOGUE_PERSON_TITLE_TEXT_SHADOW
    },
    PACKWINDOW_LABEL_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.TRANSPARENT_BLACK_2PX
    },

    LEVEL_TITLE_TEXT: {
        name: "nostroke",
        size: 45,
        color: new cc.Color(48, 83, 120, 255)
    },
    LEVEL_CURRENT_TITLE_TEXT: {
        size: 45
    },
    LEVEL_NOT_PASSED_TITLE_TEXT: {
        name: "nostroke",
        size: 45,
        color: new cc.Color(95, 95, 95, 255)
    },
    LEVEL_PASSED_TITLE_TEXT: {
        size: 45
    },

    VIPREWARDEDWINDOW_DESCRIPTION_TEXT: {
        size: 56
    },

    VIPREWARDEDWINDOW_NOTE_TEXT: {
        size: 38
    },

    PROLONG_BUTTON_AMOUNT_TEXT: {
        size: 48,
        color: cleverapps.styles.COLORS.WHITE
    },

    DAILY_TASK_FONT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.TRANSPARENT_BLACK_2PX
    },

    DAILY_TASK_REWARD_FONT: {
        size: 36,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.TRANSPARENT_BLACK_2PX
    },

    DAILY_TASK_CTA: {
        size: 43,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.TRANSPARENT_BLACK_2PX
    },

    GUIDEWINDOW_DESCRIPTION_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.WHITE,
        lineHeight: 60
    },

    LIGHT_TITLE_TEXT: {
        size: 42,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.TRANSPARENT_BLACK_2PX,
        shadow: undefined
    },

    LEVEL_REWARD_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.COLOR_BROWN
    },

    SHOP_USER_GOLD_TEXT: {
        name: "nostroke",
        size: 33,
        color: cleverapps.styles.COLORS.COLOR_FORCE_MESSAGE_TEXT
    },

    BONUSBG_TITLE: {
        color: new cc.Color(160, 74, 31, 255),
        stroke: undefined
    },

    BONUS_WORLD_WINDOW_TEXT: {
        size: 45,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT
    },

    MENUBAR_LEVEL_TEXT: {
        size: 66
    },

    HERO_ABILITIES_TEXT: {
        size: 43,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    HERO_UPGRADE: {
        size: 43,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    HERO_STATS_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    SHOP_LIVES_PRODUCT_TILE_DESCRIPTION_TEXT: {
        size: 41,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    EXP_INFO_WINDOW_AMOUNT_TEXT: {
        size: 47,
        color: cleverapps.styles.COLORS.WHITE
    },

    LANTERN_TIMER_FONT: {
        size: 43,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    MISSION_WINDOW_COUNTDOWN_TEXT: {
        size: 43,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    FIGURE_DRAGON_LIVES_TEXT: {
        size: 36,
        color: cleverapps.styles.COLORS.WHITE
    },

    OPENCHESTWINDOW_TITLE_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT,
        stroke: undefined,
        shadow: undefined
    },

    SUPPLIES_WINDOW_TEXT: {
        name: "nostroke",
        size: 47,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT
    },

    SUPPLIES_PAPER_TEXT: {
        size: 60,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    SUPPLIES_DAYS_TEXT: {
        size: 60,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    SUPPLY_REWARD_TEXT: {
        name: "stroke",
        size: 48,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT
    },

    SUPPLIES_BADGE: {
        size: 30,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    BONUSSALE_PRODUCT_REWARD: {
        name: "stroke",
        size: 50,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT
    },

    BOOSTER_BEFORE_AMOUNT_TEXT: {
        name: "default",
        size: 36
    },

    UNIT_PROB: {
        size: 32,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT,
        stroke: undefined,
        shadow: undefined
    },

    UNIT_AMOUNT: {
        size: 50,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    TILE_SHOP_BADGE_TEXT: {
        size: 58,
        stroke: cleverapps.styles.DECORATORS.TRANSPARENT_BLACK_2PX
    },

    LEVELUPWINDOW_TITLE_TEXT: {
        size: 100,
        stroke: cleverapps.styles.DECORATORS.TRANSPARENT_BLACK_3PX
    },

    TREASURE_STAGE_TEXT: {
        size: 25,
        color: cleverapps.styles.COLORS.WHITE
    },

    LIGHT_TEXT: {
        size: 35,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR
    },

    BARREL_ADS_TITLE: {
        size: 37,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT
    },

    BARREL_ADS_ITEM_TITLE: {
        size: 45,
        color: cleverapps.styles.COLORS.BARREL_ADS_ITEM_TITLE_COLOR
    },

    NOTE_WINDOW_TEXT: {
        color: cleverapps.styles.COLORS.DARK_BLUE_TEXT_COLOR
    },

    NOADS_BONUS_TEXT: {
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        size: 60
    },

    TRAVEL_BOOK_MESSAGE_TEXT: {
        size: 28
    },

    CHAIN_SALE_CLAIMED: {
        size: 55,
        color: new cc.Color(203, 3, 150)
    },

    PRIZE_BAR_TEXT: {
        size: 38
    },

    BOOSTERS_BEFORE_TEXT: {
        size: 57,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT
    },

    LANTERN_START_COMPONENT_TEXT: {
        size: 57,
        lineHeight: 45,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LANTERN_START_COMPONENT_STROKE
    },

    LANTERN_WINDOW_TEXT: {
        size: 34,
        color: cleverapps.styles.COLORS.DARK_BLUE_TEXT_COLOR
    },

    CUSTOMER_SUBTITLE_TEXT: {
        name: "default",
        size: 45,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    NOTIFICATION_TEXT: {
        color: cleverapps.styles.COLORS.WHITE
    },

    MISSION_TIMER_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.FEAST_MISSION_TIMER_STROKE,
        shadow: undefined
    },

    MISSION_MAIN_TITLE_TEXT: {
        size: 80,
        color: new cc.Color(255, 228, 0, 255),
        stroke: cleverapps.styles.DECORATORS.MISSION_STROKE,
        shadow: cleverapps.styles.DECORATORS.MISSION_SHADOW
    },

    BUTTON_BOOSTER: {
        size: 35
    },

    AMOUNT_BOOSTER: {
        size: 40
    },

    GUIDE_WINDOW_VERTICAL_STAGE_TEXT: {
        size: 55
    },

    CONGRATE_TEXT: {
        size: 96,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.MESSAGE_BLUE_STROKE
    }
});

if (cleverapps.styles.BonusLevelView) {
    cleverapps.overrideStyles(cleverapps.styles.BonusLevelView, {
        horizontal: {
            y: { align: "bottom", dy: 50 }
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.SubscriptionTile, {
    width: 400,
    height: 710,

    content: {
        y: { align: "bottom", dy: 50 },
        margin: 20
    },

    icon: {
        zOrder: 1,
        y: -46,

        bg: {
            height: 230,

            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 25 }
        }
    },

    title: {
        y: -23,

        text: {
            y: { align: "center", dy: 6 }
        }
    },

    description: {
        width: 360,
        height: 180,

        rowMargin: 3,

        text: {
            width: 270
        },

        x: 0,
        y: 0
    },

    button: {
        y: 4
    }
});

if (cleverapps.styles.LivesProductTile) {
    cleverapps.overrideStyles(cleverapps.styles.LivesProductTile, {
        description: {
            width: 350,
            height: 100,
            x: 0,
            y: 10,
            bg: {
                width: 494,
                height: 130
            }
        },

        content: {
            y: { align: "bottom", dy: 40 },
            margin: 20
        },

        icon: {
            y: 2,

            bg: {
                width: 360,
                height: 290,

                x: { align: "center", dx: 2 },
                y: { align: "center", dy: 30 }
            }
        },

        title: {
            x: 6,
            width: 446,

            text: {
                x: { align: "center", dx: -6 },
                y: { align: "center", dy: 8 }
            }
        },

        limit: {
            y: -85
        },

        badge: {
            x: { align: "right", dx: 40 },
            y: { align: "top", dy: 35 }
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.ShopWindow, {
    margin: { x: 45, y: 30 },
    padding: { x: 0, top: 30, bottom: 10 }
});

cleverapps.overrideStyles(cleverapps.styles.OfferProductTile, {
    content: {
        y: { align: "bottom", dy: 44 },
        margin: 0
    },

    icon: {
        y: -40,

        bg: {
            width: 350,
            height: 290,

            x: { align: "center", dx: 6 },
            y: { align: "center", dy: 20 }
        }
    },

    title: {
        y: -7
    },

    description: {
        y: 5,

        bg: undefined
    }
});

cleverapps.overrideStyles(cleverapps.styles.PackProductTile, {
    width: 400,
    height: 710,

    icon: {
        y: -103,

        bg: {
            height: 238,

            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 40 }
        }
    },

    title: {
        y: -52
    },

    description: {
        width: 340,
        height: 155,
        y: -8,

        bg: {
            width: 360,
            height: 175
        }
    },

    button: {
        y: 20
    }
});

cleverapps.overrideStyles(cleverapps.styles.CoinsProductTile, {
    width: 400,
    height: 710,

    button: {
        y: 20
    },

    icon: {
        bg: {
            x: { align: "center", dx: 4 },
            y: { align: "center", dy: 4 }
        }
    },

    description: undefined
});

cleverapps.overrideStyles(cleverapps.styles.SoftCurrencyTile, {
    width: 400,
    height: 710,

    button: {
        y: 20
    }
});

cleverapps.overrideStyles(cleverapps.styles.TileRewardsBlock, {
    colMargin: 10,
    paddingX: 10
});

cleverapps.overrideStyles(cleverapps.styles.TileIcon, {
    wrap: {
        dy: -5
    },
    "sweetPack": {
        wrap: {
            dy: 2
        }
    },
    "jamPack": {
        wrap: {
            dy: 2
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.TileBadge, {
    x: { align: "center", dx: 1 },
    y: { align: "center", dy: 8 },
    width: 110,
    rotation: 20
});

cleverapps.overrideStyles(cleverapps.styles.TileButton, {
    height: 100,
    width: 290
});

cleverapps.overrideStyles(cleverapps.styles.BaseProductTile, {
    height: 730,
    width: 408,
    title: {
        width: 438,
        x: 5,
        y: 0,
        text: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 8 }
        }
    },

    badge: {
        x: { align: "right", dx: 30 },
        y: { align: "top", dy: 35 }
    },

    limit: {
        y: -128
    },

    button: {
        y: 10
    },

    content: {
        y: { align: "bottom", dy: 34 },
        margin: 50
    },

    icon: {
        y: -10,

        bg: {
            width: 350,
            height: 350
        }
    }

});

if (cleverapps.styles.UnitsShopTile) {
    cleverapps.overrideStyles(cleverapps.styles.UnitsShopTile, {
        width: 396,
        height: 690,

        leftText: {
            y: { align: "center", dy: 280 }
        },

        guideButton: {
            x: { align: "center", dx: -156 },
            y: { align: "center", dy: 296 }
        },

        blockIcon: {
            x: { align: "center" },
            y: { align: "center", dy: 180 }
        },

        icon: {
            x: { align: "center" },
            y: { align: "bottom", dy: 380 },

            bg: {
                width: 350,
                height: 295,

                x: { align: "center", dx: 0 },
                y: { align: "center", dy: 172 }
            },

            scale: 1
        },

        title: {
            x: { align: "center", dx: 3 },
            y: { align: "center", dy: -44 },
            width: 430,
            height: 111,

            amount: {
                x: { align: "center" },
                y: { align: "center", dy: 10 }
            }
        },

        description: {
            x: { align: "center" },
            y: { align: "center", dy: -148 },
            width: 350,
            height: 90
        },

        button: {
            x: { align: "center" },
            y: { align: "center", dy: -250 },

            width: 280,
            height: 104
        }

    });
}

if (cleverapps.styles.UnitsShopWindow) {
    cleverapps.overrideStyles(cleverapps.styles.UnitsShopWindow, {
        bottomLine: {
            height: 102,

            content: {
                y: 4
            }
        },

        cart: {
            x: { align: "center", dx: -815 },
            y: { align: "center", dy: 7 }
        }
    });
}

if (cleverapps.styles.UnitOnCell) {
    cleverapps.overrideStyles(cleverapps.styles.UnitOnCell, {
        ground: {
            x: 3,
            y: -60
        }
    });
}

if (cleverapps.styles.RoundTabs) {
    cleverapps.overrideStyles(cleverapps.styles.RoundTabs, {
        margin: 10
    });
}

if (cleverapps.styles.UnitsShopCart) {
    cleverapps.overrideStyles(cleverapps.styles.UnitsShopCart, {
        topIcon: {
            x: { align: "center", dx: 6 },
            y: { align: "center", dy: -20 }
        },

        amountBlock: {
            x: { align: "right", dx: -30 },
            y: { align: "bottom", dy: 4 }
        },

        item: {
            scale: 0.6,
            firstItem: {
                oneItem: {
                    x: { align: "center", dx: 12 },
                    y: { align: "bottom", dy: 85 }
                },
                twoItems: {
                    x: { align: "center", dx: -3 },
                    y: { align: "bottom", dy: 74 }
                }
            },

            secondItem: {
                x: { align: "center", dx: 18 },
                y: { align: "bottom", dy: 100 }
            }
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.LeadersWindow, {
    head: {
        rank: { x: 110 },
        player: { x: 290 },
        rating: { x: 950 }
    },

    window: {
        decors: {
            "star": true
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.DailyTasksWindow, {
    tabs: {
        x: { align: "left", dx: -131 }
    }
});

if (cleverapps.styles.AchievementsTab) {
    cleverapps.overrideStyles(cleverapps.styles.AchievementsTab, {
        gridPadding: {
            y: 15
        },

        margin: {
            x: 15,
            y: 15
        },

        scroll: {
            paddingY: 160,
            y: { align: "center", dy: -50 }
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.DailyTasksTab, {
    margin: 10,

    foreground: {
        width: 842,
        height: 600,
        y: { align: "center", dy: 0 }
    },

    timer: {
        padding: {
            y: 10
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.SideBarIconView, {
    lock: {
        x: { align: "right", dx: 8 },
        y: { align: "bottom", dy: 25 }
    },

    text: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: -5 },
        width: 150,
        height: 45,
        offsetY: -2
    },

    mark: {
        x: { align: "right", dx: -20 },
        y: { align: "top", dy: -20 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.SelectFriendWindow, {
    withFriends: {
        friendContainer: {
            Name: {
                height: 30
            }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.BandButton, {
    position: {
        x: { align: "right", dx: -40 },
        y: { align: "top", dy: 24 }
    },

    icon: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 36 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.LanternView, {
    offset: {
        x: 0,
        y: 0
    }
});

if (cleverapps.styles.BoosterBeforeView) {
    cleverapps.overrideStyles(cleverapps.styles.BoosterBeforeView, {
        width: 153,
        height: 156,

        textAvailable: {
            y: { align: "bottom", dy: 4 },
            width: 115,

            bg: {
                x: { align: "center", dx: 0 },
                y: { align: "bottom", dy: -10 },
                width: 146
            }
        },

        price: {
            height: 65
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.MinimalDialogue, {
    person: {
        margin: 30
    },

    text: {
        maxHeight: 400,

        arrow: {
            left: {
                x: { align: "left", dx: -33 },
                y: { align: "center", dy: 0 }
            },
            right: {
                x: { align: "right", dx: 30 },
                y: { align: "center", dy: 0 }
            }
        },

        padding: {
            left: 30,
            right: 30
        },
        verticalPadding: {
            top: 15,
            bottom: 20,
            left: 20,
            right: 20
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.DialogueView, {
    widthPadding: 30,

    arrow: {
        y: { align: "top", dy: 41 }
    },

    text: {
        padding: {
            x: 50,
            y: 50
        },
        verticalPadding: {
            fromWallX: 30,
            x: 30,
            y: 40
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.DialoguePersonTitleView, {
    text: {
        y: { align: "center", dy: 3 },
        padding: {
            x: 25,
            y: 15
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.ToolbarItemView, {
    timer: {
        bg: {
            width: 114,
            height: 30
        }
    },
    reward: {
        x: { align: "center", dx: 7 },
        y: { align: "bottom", dy: 17 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.LevelPassWindow, {
    "progress": {
        x: { align: "right", dx: -6 }
    },

    window: {
        padding: {
            top: 100,
            bottom: 40
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.RateWindow, {
    text: {
        y: { align: "center" }
    }
});

cleverapps.overrideStyles(cleverapps.styles.DailyTaskView, {
    margin: 20,

    padding: {
        left: 35,
        right: 35,
        top: 20,
        bottom: 20
    },

    icon: {
        x: { align: "left", dx: 60 }
    },

    title: {
        height: 80
    },

    titleAndRewards: {
        width: 394,
        height: 130,
        x: { align: "left", dx: 2 },
        y: { align: "center", dy: 3 },

        wide: {
            width: 606
        }
    },

    progressBar: {
        text: {
            dy: -2
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.LoseWindow, {
    content: {
        padding: {
            top: 20
        }
    },

    life: {
        width: 70,
        height: 60
    },

    noBackground: true
});

cleverapps.overrideStyles(cleverapps.styles.ToolbarView, {
    margin: 12,
    position: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 5 }
    },
    bg: {
        height: 105,
        offsetY: 0
    }
});

cleverapps.overrideStyles(cleverapps.styles.MenuBarView, {
    mobileScale: [0.9, 0.9, 0.9]
});

cleverapps.overrideStyles(cleverapps.styles.MenuBarItem, {
    noPlusDx: 0,

    bg: {
        width: 210,
        minWidth: 165,
        height: 60,
        padding: {
            right: 30
        }
    },

    plusButton: {
        offset: {
            x: -16,
            y: -6
        }
    },

    icon: {
        x: 15
    },

    attention: {
        x: { align: "right", dx: 10 },
        y: { align: "bottom", dy: -23 }
    },

    helpButton: {
        width: 25
    },

    timer: {
        icon: false,
        height: 44,
        padding: {
            x: 20
        },

        position: {
            x: { align: "center", dx: 21 },
            y: { align: "bottom", dy: -44 }
        },

        text: {
            x: { align: "center", dx: 1 },
            y: { align: "center", dy: 2 },
            width: 85
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.MenuBarTextView, {
    noColoredText: true
});

cleverapps.overrideStyles(cleverapps.styles.Tabs, {
    margin: 20,

    buttons: {
        width: 127,
        height: 96,

        padding: {
            bottom: 15
        }
    },

    attention: {
        x: { align: "left", dx: -20 },
        y: { align: "top", dy: -5 }
    }
});

cleverapps.styles.UI.glyphScale = {
    scale: 0.85
};

cleverapps.overrideStyles(cleverapps.styles.SettingsWindow, {
    button: {
        margin: 10,
        width: 400,
        height: 104
    }
});

cleverapps.overrideStyles(cleverapps.styles.CloseButton, {
    scale: [0.8, 1, 1],
    position: [{
        x: { align: "right", dx: -12 },
        y: { align: "top", dy: -16 }
    }, {
        x: { align: "right", dx: -12 },
        y: { align: "top", dy: -12 }
    }, {
        x: { align: "right", dx: -12 },
        y: { align: "top", dy: -12 }
    }]
});

cleverapps.overrideStyles(cleverapps.styles.Window, {
    border: {
        x: 17,
        top: 101,
        bottom: 25
    },
    padding: {
        x: 40,
        top: 55,
        bottom: 55
    },
    HelpButton: {
        position: [{
            x: { align: "left", dx: 25 },
            y: { align: "top", dy: -15 }
        }, {
            x: { align: "left", dx: 35 },
            y: { align: "top", dy: -25 }
        }, {
            x: { align: "left", dx: 35 },
            y: { align: "top", dy: -25 }
        }],
        zOrder: 3
    },
    BottomButton: {
        height: 104
    },

    Background: {
        padding: {
            x: 30,
            y: 20
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.SideBarSlot, {
    margin: 40
});

if (cleverapps.styles.StarChestWindow) {
    cleverapps.overrideStyles(cleverapps.styles.StarChestWindow, {
        margin: 40,

        chest: {
            height: 300,

            y: {
                align: "center",
                dy: 40
            }
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.DailyTasksCompleteAllView, {
    textLayout: {
        width: cleverapps.config.type === "merge" ? 700 : 520
    },

    prize: {
        y: { align: "bottom", dy: -14 }
    },

    prizePosition: {
        x: { align: "right", dx: 50 },
        y: { align: "center", dy: 16 }
    },

    padding: {
        left: 50,
        right: 50
    },

    icon: {
        dy: -10
    }
});

cleverapps.overrideStyles(cleverapps.styles.PrizeBarComponent, {
    progressBar: {
        barText: {
            dy: -2
        }
    },

    prize: {
        x: { align: "right", dx: 50 },
        y: { align: "center", dy: 6 }
    },

    mark: {
        x: { align: "right", dx: 20 },
        y: { align: "center", dy: 0 }
    },

    button: {
        y: { align: "center", dy: -2 }
    }
});

if (cleverapps.styles.DailyCupView) {
    cleverapps.overrideStyles(cleverapps.styles.DailyCupView, {
        title: {
            width: 500,
            height: 97,

            bg: {
                x: { align: "center" },
                y: { align: "center", dy: -15 }
            },

            text: {
                width: 360,
                y: { align: "center", dy: -7 }
            }
        },

        playButton: {
            width: 300,
            height: 100
        }
    });
}

if (cleverapps.styles.CupTimer) {
    cleverapps.overrideStyles(cleverapps.styles.CupTimer, {
        textNode: {
            width: 500
        }
    });
}

if (cleverapps.styles.BackgroundsWindow) {
    cleverapps.overrideStyles(cleverapps.styles.BackgroundsWindow, {
        padding: {
            right: -25,
            left: -25
        },

        tape: {
            x: { align: "right", dx: 18 }
        },

        item: {
            bg: {
                padding: {
                    x: 10,
                    y: 10
                }
            }
        }
    });
}

if (cleverapps.styles.ExtendUnlimitedLivesWindow) {
    cleverapps.overrideStyles(cleverapps.styles.ExtendUnlimitedLivesWindow, {
        animation: {
            height: 300
        }
    });
}

if (cleverapps.styles.CookieJarView) {
    cleverapps.overrideStyles(cleverapps.styles.CookieJarView, {
        scale: 0.6
    });
}

cleverapps.overrideStyles(cleverapps.styles.ShopUserGoldView, {
    items: {
        boosters: {
            dy: 0
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.ConfirmExitWindow, {
    animation: {
        width: 200,
        height: 200
    }
});

if (cleverapps.styles.LanternWindow) {
    cleverapps.overrideStyles(cleverapps.styles.LanternWindow, {
        margin: 20,
        window: {
            padding: {
                top: 35,
                bottom: 35,
                x: 20
            }
        },
        timer: {
            y: { align: "top", dy: -5 }
        },
        bonuses: {
            margin: 3,
            x: { align: "center" },
            y: {
                align: "bottom",
                dy: 30
            },
            bonusBg: {
                width: 168,
                height: 168
            },
            amount: {
                x: {
                    align: "right",
                    dx: -7
                },
                y: {
                    align: "bottom",
                    dy: 7
                },

                bg: {
                    x: { align: "center" },
                    y: {
                        align: "center",
                        dy: -5
                    },
                    width: 86,
                    height: 86
                }
            }
        },
        text: {
            width: 620,
            height: 130
        },
        animation: {
            y: 12
        }
    });
}

if (cleverapps.styles.ProlongationOfferView) {
    cleverapps.overrideStyles(cleverapps.styles.ProlongationOfferView, {
        button: { height: 100 }
    });
}

cleverapps.overrideStyles(cleverapps.styles.WindowTitle.Types, {
    bg_window: {
        background: undefined,
        titleSideOffset: {
            x: 100
        },

        scale: [0.8, 1, 1],
        position: [{
            x: { align: "center" },
            y: { align: "top", anchor: false, dy: -66 }
        }, {
            x: { align: "center" },
            y: { align: "top", anchor: false, dy: -80 }
        }, {
            x: { align: "center" },
            y: { align: "top", anchor: false, dy: -80 }
        }],

        text: {
            y: { align: "center", dy: 32 }
        },

        helpButton: {
            x: { align: "left", dx: 40 },
            y: { align: "center", dy: 15 },
            padding: {
                x: 30
            }
        }
    },

    nobg_window: {
        background: {
            padding: {
                x: 140
            }
        },

        text: {
            x: { align: "center" },
            y: { align: "center", dy: 12 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.CrossPromoWindow, {
    window: {
        border: {
            x: 10,
            top: 101,
            bottom: 25
        },
        padding: {
            x: 0,
            top: 0,
            bottom: 0
        }
    },

    top: {
        width: 1200,
        height: 45,
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: 25 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.NotificationView, {
    progress: {
        height: 52
    }
});

cleverapps.overrideStyles(cleverapps.styles.AdBlockWindow, {
    window: {
        padding: {
            top: 160
        }
    }
});

if (cleverapps.styles.ExtraWindow) {
    cleverapps.overrideStyles(cleverapps.styles.ExtraWindow, {
        window: {
            padding: {
                top: 100,
                bottom: 80
            },
            CloseButton: {
                x: { align: "right", dx: 20 },
                y: { align: "top", dy: 20 }
            }
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.LevelStartWindow, {
    padding: {
        top: 30
    },

    lanternStart: {
        margin: 25,
        padding: {
            left: -25,
            right: -25,
            top: 5
        },

        coinBadge: {
            label: {
                anchor: { x: 0.5, y: 0.5 },
                x: { align: "center" },
                y: { align: "center" },
                bonusText: {
                    width: 150,
                    height: 95
                }
            },
            x: { align: "right", dx: 0 },
            y: { align: "bottom", dy: 164 }
        }
    }
});
if (cleverapps.config.type === "match3") {
    cleverapps.overrideStyles(cleverapps.styles.LevelStartWindow, {
        hardLevel: {
            x: { align: "right", dx: -50 },
            y: { align: "bottom", dy: 50 },
            pulseUp: true
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.PassBuyTicketWindow, {
    margin: 30,

    rewards: {
        icon: {
            dy: 13
        }
    },

    badge: {
        x: { align: "left", dx: -46 },
        y: { align: "top", dy: 6 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.LevelView, {
    clickSize: {
        width: 165,
        height: 165
    },

    LevelId: {
        x: { dx: 5 },
        y: { dy: -5 },
        scale: 0.75
    },

    prize: {
        x: { align: "center", dx: 10 },
        y: { align: "top", dy: 180 }
    }
});

if (cleverapps.styles.ShortMetaProgressView) {
    cleverapps.overrideStyles(cleverapps.styles.ShortMetaProgressView, {
        dy: 195,

        margin: {
            x: 20,
            y: 30,
            mobileY: 50
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.UpMenuContainer, {
    padding: [{
        left: -12,
        right: -5
    }, {
        left: -14,
        right: -5
    }, {
        left: -18,
        right: -5
    }],

    controlButtons: {
        y: { align: "center", dy: 0 }
    }
});

if (cleverapps.styles.MenuBarGameLevelView) {
    cleverapps.overrideStyles(cleverapps.styles.MenuBarGameLevelView, {
        margin: -33,
        value: {
            offset: {
                x: -2,
                y: -4
            }
        },

        exp: {
            offset: {
                x: 16,
                y: 0
            },

            padding: {
                x: 80,
                y: 5
            }
        },

        crown: {
            offset: {
                y: -18
            }
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.RestoreProgressButton, {
    content: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 8 }
    }
});

if (cleverapps.styles.QuestIcon) {
    cleverapps.overrideStyles(cleverapps.styles.QuestIcon, {
        progress: {
            x: { align: "center" },
            y: { align: "bottom", dy: -2 },

            text: {
                x: { align: "center" },
                y: { align: "center", dy: -2 }
            }
        }
    });
}

if (cleverapps.styles.UnitsLibraryWindow) {
    cleverapps.overrideStyles(cleverapps.styles.UnitsLibraryWindow, {
        width: 1860,
        height: 1060,

        padding: {
            x: 50,
            y: 20
        },

        tabs: {
            x: { align: "left", dx: -140 }
        },

        window: {
            border: {
                top: 20
            },

            Foreground: {
                padding: {
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0
                }
            }
        }
    });
}

if (cleverapps.styles.FamilyLayout) {
    cleverapps.overrideStyles(cleverapps.styles.FamilyLayout, {
        title: {
            x: { align: "center" },
            y: { align: "top", dy: 15 }
        },

        grid: {
            x: { align: "left" },
            y: { align: "center", dy: 0 }
        }
    });
}

if (cleverapps.styles.HeroesTab) {
    cleverapps.overrideStyles(cleverapps.styles.HeroesTab, {
        scroll: {
            width: 1820
        }
    });
}

if (cleverapps.styles.HeroDetailsLayout) {
    cleverapps.overrideStyles(cleverapps.styles.HeroDetailsLayout, {
        height: 560,

        buttonInfo: {
            x: { align: "right", dx: -10 },
            y: { align: "top", dy: -10 }
        },

        infoHeroText: {
            x: { align: "center" },
            y: { align: "center", dy: 20 }
        },

        grid: {
            width: 1712,

            x: { align: "left", dx: 24 },
            y: { align: "center", dy: -28 }
        }
    });
}

if (cleverapps.styles.UnitLibraryIcon) {
    cleverapps.overrideStyles(cleverapps.styles.UnitLibraryIconBg, {
        width: 240,
        height: 320,

        title: {
            x: { align: "center" },
            y: { align: "center", dy: 105 }
        }
    });

    cleverapps.overrideStyles(cleverapps.styles.UnitLibraryIconFooter, {
        width: 200,
        height: 100,

        collectButton: {
            height: 63,
            type: cleverapps.styles.UI.Button.Images.small_button_green
        }
    });
}

if (cleverapps.styles.OrdersWindow) {
    cleverapps.overrideStyles(cleverapps.styles.OrdersWindow, {
        width: 1240,

        roof: {
            x: { align: "center", dx: 0 },
            y: { align: "top", dy: 0 },
            width: 1380,

            marquise: {
                x: 30,
                y: 0
            },

            title: {
                x: { align: "center", dx: 0 },
                y: { align: "top", dy: 28 },
                width: 500
            }
        },

        Background: {
            image: bundles.orderswindow.frames.ow_window_background
        },

        tabs: {
            x: { align: "left", dx: -20 },
            y: { align: "top", dy: 88 },
            direction: cleverapps.UI.HORIZONTAL,
            width: 160,
            height: 100,
            margin: -10
        }
    });
}

if (cleverapps.styles.OrdersTab) {
    cleverapps.overrideStyles(cleverapps.styles.OrdersTab, {
        table: {
            x: { align: "center", dx: 0 },
            y: { align: "bottom", dy: -20 },
            width: 1350,
            height: 420
        },

        shelve: {
            x: { align: "center" },
            y: { align: "center", dy: -15 },
            padding: 150
        },

        grid: {
            x: { align: "left", dx: -24 },
            y: { align: "center", dy: -250 },
            width: 1286,

            padding: {
                x: 20,
                y: 30
            }
        }
    });
}

if (cleverapps.styles.OrderDetails) {
    cleverapps.overrideStyles(cleverapps.styles.OrderDetails, {
        width: 1200,

        bucket: {
            left: {
                x: { align: "center", dx: -395 },
                y: { align: "center", dy: -16 }
            },
            right: {
                x: { align: "center", dx: 400 },
                y: { align: "center", dy: -16 }
            },
            width: 500
        },

        button: {
            x: { align: "center", dx: 5 },
            y: { align: "center", dy: -80 },
            width: 300,
            height: 104
        },

        ingredients: {
            x: { align: "center", dx: -400 },
            y: { align: "bottom", dy: 52 }
        },

        rewards: {
            x: { align: "center", dx: 390 },
            y: { align: "bottom", dy: 70 }
        }
    });
}

if (cleverapps.styles.IngredientOrderIcon) {
    cleverapps.overrideStyles(cleverapps.styles.IngredientOrderIcon, {
        text: {
            width: 130,
            x: { align: "center", dx: -100 },
            y: { align: "bottom", dy: -10 }
        }
    });
}

if (cleverapps.styles.OrderIcon) {
    cleverapps.overrideStyles(cleverapps.styles.OrderIcon, {
        width: 222,

        highlight: {
            border: 20
        },

        button: {
            type: cleverapps.styles.UI.Button.Images.small_button_green
        },

        pin: {
            x: { align: "center", dx: -80 },
            y: { align: "top", dy: 0 },
            rotation: 0,
            icon: {
                x: { align: "center" },
                y: { align: "center", dy: 20 }
            }
        },

        ingredients: {
            width: 184
        },

        timer: {
            x: { align: "left", dx: -6 },
            y: { align: "top", dy: 14 },

            background: {
                x: { align: "center", dx: 28 },
                y: { align: "top", dy: -13 },
                margin: -2
            }
        },

        rewards: {
            width: 184,
            x: { align: "center" },
            y: { align: "center", dy: 20 }
        }
    });
}

if (cleverapps.styles.CustomerIngredientViewInfoView) {
    cleverapps.overrideStyles(cleverapps.styles.CustomerIngredientViewInfoView, {
        bg: {
            width: 78,
            height: 74,
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 1 }
        }
    });
}

if (cleverapps.styles.InfoView) {
    cleverapps.overrideStyles(cleverapps.styles.InfoView, {
        button: {
            height: 63,
            type: {
                default: cleverapps.styles.UI.Button.Images.small_button_green,
                secondary: cleverapps.styles.UI.Button.Images.small_button_blue
            }
        },

        pointer: {
            y: -8
        },

        label: {
            padding: {
                x: 6,
                top: 15,
                bottom: 15
            }
        },

        timer: {
            padding: {
                x: 6,
                top: 15,
                bottom: 20
            }
        },

        upgradableLevel: {
            x: { align: "center", dx: 0 },
            y: { align: "top", dy: 54 },

            text: {
                x: { align: "center", dx: -3 },
                y: { align: "center", dy: -4 }
            }
        }
    });
}

if (cleverapps.styles.PrizeView) {
    cleverapps.overrideStyles(cleverapps.styles.PrizeView, {
        "default": {
            width: 90,
            height: 100,

            pointer: {
                dy: -1
            }
        }
    });
}

if (cleverapps.styles.SpeedUpWindow) {
    cleverapps.overrideStyles(cleverapps.styles.SpeedUpWindow, {
        timer: {
            button: {
                height: 99
            }
        },

        buttons: {
            padding: {
                bottom: 10
            },

            button: {
                width: 340,
                height: 104
            }
        }

    });
}

if (cleverapps.styles.TreasureSearchView) {
    cleverapps.overrideStyles(cleverapps.styles.TreasureSearchView, {
        size: [{
            width: 970, height: 1130
        }, {
            width: 970, height: 1130
        }, {
            width: 970, height: 1130
        }],

        header: {
            x: { align: "center", dx: 0 },
            y: { align: "top", dy: 2 }
        },

        goalBlock: {
            "g": {
                positions: [{
                    x: { align: "center", dx: 68 }, y: { align: "top", dy: -101 }
                }, {
                    x: { align: "center", dx: 68 }, y: { align: "top", dy: -101 }
                }, {
                    x: { align: "center", dx: 68 }, y: { align: "top", dy: -101 }
                }],
                scale: 0.47
            },
            "h": {
                positions: [{
                    x: { align: "center", dx: -4 }, y: { align: "top", dy: -49 }
                }, {
                    x: { align: "center", dx: -4 }, y: { align: "top", dy: -49 }
                }, {
                    x: { align: "center", dx: -4 }, y: { align: "top", dy: -49 }
                }],
                scale: 0.34
            },
            "j": {
                positions: [{
                    x: { align: "center", dx: -83 }, y: { align: "top", dy: -106 }
                }, {
                    x: { align: "center", dx: -83 }, y: { align: "top", dy: -106 }
                }, {
                    x: { align: "center", dx: -83 }, y: { align: "top", dy: -106 }
                }],
                scale: 0.32
            },
            "k": {
                positions: [{
                    x: { align: "center", dx: -8 }, y: { align: "top", dy: -165 }
                }, {
                    x: { align: "center", dx: -8 }, y: { align: "top", dy: -165 }
                }, {
                    x: { align: "center", dx: -8 }, y: { align: "top", dy: -165 }
                }],
                rotation: 90,
                scale: 0.26
            }
        },

        progress: {
            bg: {
                size: [{
                    width: 986, height: 170
                }, {
                    width: 986, height: 170
                }, {
                    width: 986, height: 170
                }],

                positions: [{
                    x: { align: "center", dx: 0 }, y: { align: "top", dy: -284 }
                }, {
                    x: { align: "center", dx: 0 }, y: { align: "top", dy: -284 }
                }, {
                    x: { align: "center", dx: 0 }, y: { align: "top", dy: -284 }
                }]
            },

            progressBar: {
                width: 730,
                positions: [{
                    x: { align: "center", dx: 0 }, y: { align: "center", dy: 14 }
                }, {
                    x: { align: "center", dx: 0 }, y: { align: "center", dy: 14 }
                }, {
                    x: { align: "center", dx: 0 }, y: { align: "center", dy: 14 }
                }]
            },

            currentStage: {
                width: 140,
                height: 170,
                dy: -2
            },

            chestBlock: {
                margins: {
                    vertical: 90,
                    horizontal: 90
                },
                positions: [{
                    x: { align: "center", dx: 0 }, y: { align: "center", dy: 10 }
                }, {
                    x: { align: "center", dx: 0 }, y: { align: "center", dy: 10 }
                }, {
                    x: { align: "center", dx: 0 }, y: { align: "center", dy: 10 }
                }],

                text: {
                    y: { align: "center", dy: -60 }
                }
            }
        },

        miningTool: {
            bg: {
                width: 92,
                height: 160,
                positions: [{
                    x: { align: "right", dx: 98 }, y: { align: "center", dy: 0 }
                }, {
                    x: { align: "right", dx: 98 }, y: { align: "center", dy: 0 }
                }, {
                    x: { align: "right", dx: 98 }, y: { align: "center", dy: 0 }
                }]
            },

            icon: {
                x: { align: "center", dx: 1 },
                y: { align: "top", dy: -11 }
            },

            amountBg: {
                width: 75,
                height: 45,
                x: { align: "center", dx: -4 }
            }
        },

        ads: {
            positions: [{
                x: { align: "left", dx: 20 }, y: { align: "bottom", dy: 62 }
            }, {
                x: { align: "left", dx: 20 }, y: { align: "bottom", dy: 62 }
            }, {
                x: { align: "left", dx: 20 }, y: { align: "bottom", dy: 62 }
            }]
        },

        scroll: {
            size: [{
                width: 988, height: 745
            }, {
                width: 988, height: 745
            }, {
                width: 988, height: 745
            }],

            positions: [{
                x: { align: "center", dx: 0 }, y: { align: "top", dy: -397 }
            }, {
                x: { align: "center", dx: 0 }, y: { align: "top", dy: -397 }
            }, {
                x: { align: "center", dx: 0 }, y: { align: "top", dy: -397 }
            }]
        },

        shadow: {
            size: [{
                width: 986, height: 705
            }, {
                width: 986, height: 705
            }, {
                width: 990, height: 705
            }],

            positions: [{
                x: { align: "center", dx: 0 }, y: { align: "bottom", dy: -90 }
            }, {
                x: { align: "center", dx: 0 }, y: { align: "bottom", dy: -90 }
            }, {
                x: { align: "center", dx: 0 }, y: { align: "bottom", dy: -90 }
            }]
        }
    });
}

if (cleverapps.styles.TreasureSearchCellView) {
    cleverapps.overrideStyles(cleverapps.styles.TreasureSearchCellView, {
        width: 143,
        height: 143
    });
}

if (cleverapps.styles.TreasureSearchWindow) {
    cleverapps.overrideStyles(cleverapps.styles.TreasureSearchWindow, {
        content: {
            y: { align: "top", dy: 8 }
        },

        timer: {
            positions: [{
                x: { align: "center", dx: 0 }, y: { align: "bottom", dy: 0 }
            }, {
                x: { align: "center", dx: 0 }, y: { align: "bottom", dy: 0 }
            }, {
                x: { align: "center", dx: 0 }, y: { align: "bottom", dy: 0 }
            }]
        },

        window: {
            padding: {
                x: 8,
                bottom: 8,
                top: 6
            }
        }
    });
}

if (cleverapps.styles.SuppliesWindow) {
    cleverapps.overrideStyles(cleverapps.styles.SuppliesWindow, {
        window: {
            padding: [
                {
                    left: 10,
                    right: 10,
                    top: 0,
                    bottom: 60
                },
                {
                    left: 10,
                    right: 10,
                    top: 0,
                    bottom: 90
                },
                {
                    left: 10,
                    right: 10,
                    top: 0,
                    bottom: 90
                }
            ]
        },

        size: [
            { width: 880, height: 1210 },
            { width: 1290, height: 540 },
            { width: 1290, height: 540 }
        ],

        text: {
            width: 750,

            positions: [
                { x: { align: "center" }, y: { align: "center", dy: -105 } },
                { x: { align: "center", dx: 220 }, y: { align: "top", dy: -35 } },
                { x: { align: "center", dx: 220 }, y: { align: "top", dy: -35 } }
            ]
        },

        textWithTimer: {
            positions: [
                { x: { align: "center" }, y: { align: "bottom", dy: -20 } },
                { x: { align: "center", dx: 200 }, y: { align: "bottom", dy: 5 } },
                { x: { align: "center", dx: 200 }, y: { align: "bottom", dy: 5 } }
            ]
        },

        paper: {
            positions: [
                { x: { align: "center" }, y: { align: "top", dy: -65 } },
                { x: { align: "left", dx: 10 }, y: { align: "center", dy: -55 } },
                { x: { align: "left", dx: 10 }, y: { align: "center", dy: -55 } }
            ]
        },

        daysLayout: {
            positions: [
                { x: { align: "center", dx: -8 }, y: { align: "bottom", dy: 30 } },
                { x: { align: "center", dx: 210 }, y: { align: "center", dy: -40 } },
                { x: { align: "center", dx: 210 }, y: { align: "center", dy: -40 } }
            ]
        },

        strikethrough: {
            positions: [
                { x: { align: "center" }, y: { align: "bottom", dy: -35 } },
                { x: { align: "center", dx: 40 }, y: { align: "bottom", dy: -25 } },
                { x: { align: "center", dx: 40 }, y: { align: "bottom", dy: -25 } }
            ]
        },

        button: {
            positions: [
                { x: { align: "center" }, y: { align: "bottom", dy: -210 } },
                { x: { align: "center", dx: 40 }, y: { align: "bottom", dy: -250 } },
                { x: { align: "center", dx: 40 }, y: { align: "bottom", dy: -250 } }
            ],

            badge: {
                y: { align: "center", dy: 26 },
                text: {
                    x: { align: "center", dx: 12 },
                    y: { align: "center", dy: 4 },
                    height: 64
                }
            }
        }
    });
}

if (cleverapps.styles.SupplyPaperView) {
    cleverapps.overrideStyles(cleverapps.styles.SupplyPaperView, {
        title: {
            size: [
                { width: 420 },
                { width: 420 },
                { width: 420 }
            ],

            positions: [
                { x: { align: "center" }, y: { align: "top", dy: 44 } },
                { x: { align: "center" }, y: { align: "top", dy: 43 } },
                { x: { align: "center" }, y: { align: "top", dy: 43 } }
            ],

            text: {
                width: 300,
                height: 70,

                positions: [
                    { x: { align: "center", dx: -4 }, y: { align: "center", dy: 4 } },
                    { x: { align: "center", dx: -4 }, y: { align: "center", dy: 4 } },
                    { x: { align: "center", dx: -4 }, y: { align: "center", dy: 4 } }
                ]

            }
        },

        rewards: {
            positions: [
                { x: { align: "center" }, y: { align: "center", dy: -18 } },
                { x: { align: "center" }, y: { align: "center", dy: -10 } },
                { x: { align: "center" }, y: { align: "center", dy: -10 } }
            ],

            size: [
                { width: 750, height: 460 },
                { width: 370, height: 450 },
                { width: 370, height: 450 }
            ],

            margin: [
                { x: 100, y: 40 },
                { y: 0 },
                { y: 0 }
            ]
        },

        decor: false
    });
}

if (cleverapps.styles.SupplyDayView) {
    cleverapps.overrideStyles(cleverapps.styles.SupplyDayView, {
        title: {
            y: { align: "top", dy: -25 },
            lineBreak: true
        },

        last: {
            title: {
                y: { align: "center", dy: 2 },
                lineBreak: true
            }
        }
    });
}

if (cleverapps.styles.PromotionSaleWindow) {
    cleverapps.overrideStyles(cleverapps.styles.PromotionSaleWindow[Offers.TYPES.PROMOTION_SALE], {
        title: {
            y: { align: "center", dy: 424 },
            width: 390
        },

        description: {
            y: { align: "bottom", dy: 35 }
        },

        header: {
            y: { align: "center", dy: -15 }
        },

        lots: {
            y: { align: "center", dy: -102 },

            marginX: 0
        }
    });
}

if (cleverapps.styles.PromotionSaleLot) {
    cleverapps.overrideStyles(cleverapps.styles.PromotionSaleLot[Offers.TYPES.PROMOTION_SALE], {
        height: 640,

        reward: {
            width: 380,
            height: 443,

            y: { align: "center", dy: 60 },

            margin: {
                x: 5,
                y: 5
            },

            icons: {
                y: { align: "center", dy: 10 },
                wrap: {
                    width: 150,
                    height: 150
                }
            }
        },

        badge: {
            x: { align: "right", dx: 0 },
            y: { align: "top", dy: -17 },
            text: {
                x: { align: "center", dx: 3 },
                y: { align: "center", dy: 3 },
                width: 100,
                height: 80
            }
        },

        button: {
            y: { align: "bottom", dy: 45 }
        }
    });
}

if (cleverapps.styles.ChainSaleLot) {
    cleverapps.overrideStyles(cleverapps.styles.ChainSaleLot, {
        button: {
            SKIP_RESOLUTION: {
                padding: {
                    left: 0.3,
                    right: 0.3,
                    top: 0.03,
                    bottom: 0.2
                }
            }
        }
    });
}

if (cleverapps.styles.TextWithIcon) {
    cleverapps.overrideStyles(cleverapps.styles.TextWithIcon, {
        margin: 4
    });
}

if (cleverapps.styles.ChestPrizeProbWindow) {
    cleverapps.overrideStyles(cleverapps.styles.ChestPrizeProbWindow, {
        prize: {
            probText: {
                y: { align: "bottom", dy: 4 }
            }
        }
    });
}

if (cleverapps.styles.OpenChestWindow) {
    cleverapps.overrideStyles(cleverapps.styles.OpenChestWindow, {
        showAd: {
            buttons: {
                padding: {
                    top: -10,
                    bottom: 20
                },
                margin: 40
            }
        },

        chest: {
            scale: 1.4,
            margin: 10,
            extra: {
                scale: 1.2
            }
        }
    });
}

if (cleverapps.styles.SellUnitWindow) {
    cleverapps.overrideStyles(cleverapps.styles.SellUnitWindow, {
        icon: {
            scale: 1.3
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.LoadingAnimation, {
    text: {
        x: { align: "center" },
        y: { align: "center", dy: -125 },

        width: 240
    }
});

cleverapps.overrideStyles(cleverapps.styles.GameMessageView, {
    showAnimation: "fromCenter"
});

(function () {
    [
        cleverapps.styles.UI.Button.Images.button_green,
        cleverapps.styles.UI.Button.Images.button_blue,
        cleverapps.styles.UI.Button.Images.button_red,
        cleverapps.styles.UI.Button.Images.button_purple
    ].forEach(function (obj) {
        cleverapps.overrideStyles(obj, {
            SKIP_RESOLUTION: {
                padding: {
                    left: 0.3,
                    right: 0.3,
                    top: 0.1,
                    bottom: 0.25
                }
            }
        });
    });

    [
        cleverapps.styles.UI.Button.Images.small_button_blue,
        cleverapps.styles.UI.Button.Images.small_button_green,
        cleverapps.styles.UI.Button.Images.field_button_green,
        cleverapps.styles.UI.Button.Images.field_button_blue
    ].forEach(function (obj) {
        cleverapps.overrideStyles(obj, {
            SKIP_RESOLUTION: {
                padding: {
                    left: 0.3,
                    right: 0.3,
                    top: 0.02,
                    bottom: 0.15
                }
            }
        });
    });
}());

if (cleverapps.styles.ChainSaleWindow) {
    cleverapps.overrideStyles(cleverapps.styles.ChainSaleWindow, {
        bg: {
            positions: [
                { x: { align: "center", dx: 0 }, y: { align: "bottom", dy: -90 } },
                { x: { align: "center", dx: 0 }, y: { align: "center", dy: -40 } },
                { x: { align: "center", dx: 0 }, y: { align: "center", dy: -40 } }
            ]
        }
    });
}

if (cleverapps.styles.StickersBookWindow) {
    cleverapps.overrideStyles(cleverapps.styles.StickersBookWindow, {
        size: [
            { width: 900, height: 1120 },
            { width: 1500, height: 1010 },
            { width: 1500, height: 1010 }
        ],

        scroll: {
            size: [
                { width: 860, height: 975 },
                { width: 1490, height: 1100 },
                { width: 1490, height: 1100 }
            ],
            position: [
                { x: { align: "center" }, y: { align: "center", dy: -59 } },
                { x: { align: "center" }, y: { align: "center", dy: -45 } },
                { x: { align: "center" }, y: { align: "center", dy: -45 } }
            ]
        },

        fg: undefined,

        jokers: {
            position: [
                { x: { align: "right", dx: -34 }, y: { align: "top", dy: -15 } },
                { x: { align: "right", dx: -42 }, y: { align: "top", dy: -17 } },
                { x: { align: "right", dx: -42 }, y: { align: "top", dy: -17 } }
            ]
        },

        progressView: {
            width: 350,
            verticalWidth: 283,
            position: [
                { x: { align: "center", dx: 50 }, y: { align: "top", dy: -40 } },
                { x: { align: "center", dx: 40 }, y: { align: "top", dy: -42 } },
                { x: { align: "center", dx: 40 }, y: { align: "top", dy: -42 } }
            ],
            joker: [
                { x: { align: "left", dx: -50 }, y: { align: "center" } },
                { x: { align: "left", dx: -20 }, y: { align: "center" } },
                { x: { align: "left", dx: -20 }, y: { align: "center" } }
            ],
            text: {
                dy: -1
            }
        },

        grid: {
            margin: {
                x: 2,
                y: -5
            },
            padding: {
                top: 30,
                right: 10
            }
        },

        collectionsAmount: {
            position: [
                { x: { align: "left", dx: 32 }, y: { align: "top", dy: -19 } },
                { x: { align: "left", dx: 43 }, y: { align: "top", dy: -21 } },
                { x: { align: "left", dx: 43 }, y: { align: "top", dy: -21 } }
            ]
        },

        stickersAmount: {
            position: [
                { x: { align: "left", dx: 248 }, y: { align: "top", dy: -51 } },
                { x: { align: "left", dx: 300 }, y: { align: "top", dy: -20 } },
                { x: { align: "left", dx: 300 }, y: { align: "top", dy: -20 } }
            ]
        }

    });
}

if (cleverapps.styles.StickersComponent) {
    cleverapps.overrideStyles(cleverapps.styles.StickersComponent, {
        fg: false,
        layout: {
            margin: [{ x: 30, y: 20 }, { x: 30, y: 20 }, { x: 40, y: 45 }],
            x: { align: "center" },
            y: { align: "center", dy: -66 }
        }
    });
}

if (cleverapps.styles.StickersCollectionWindow) {
    cleverapps.overrideStyles(cleverapps.styles.StickersCollectionWindow, {
        size: [
            { width: 1000, height: 1200 },
            { width: 1000, height: 1200 },
            { width: 1670, height: 870 }
        ],

        prize: {
            width: 400,
            position: [
                { x: { align: "center", dx: 0 }, y: { align: "top", dy: -34 } },
                { x: { align: "center", dx: 0 }, y: { align: "top", dy: -34 } },
                { x: { align: "center" }, y: { align: "top", dy: -35 } }
            ]
        },

        jokers: {
            position: [
                { x: { align: "right", dx: -49 }, y: { align: "top", dy: -24 } },
                { x: { align: "right", dx: -49 }, y: { align: "top", dy: -24 } },
                { x: { align: "right", dx: -56 }, y: { align: "top", dy: -26 } }
            ]
        },

        text: {
            dy: -1
        }
    });
    cleverapps.overrideFonts(cleverapps.styles.FONTS, {
        STICKERS_PROGRESS_BAR_TEXT: {
            size: 38
        },
        STICKER_JOKER_BUTTON_TEXT: {
            size: 80,
            color: cleverapps.styles.COLORS.WHITE,
            stroke: cleverapps.styles.DECORATORS.TRANSPARENT_BLACK_2PX
        }
    });
}

if (cleverapps.styles.StickerView) {
    cleverapps.overrideStyles(cleverapps.styles.StickerView, {
        label: {
            textBg: false,
            text: {
                x: { align: "center", dx: 0 },
                y: { align: "bottom", dy: 20 },
                width: 240
            }
        },

        image: {
            x: { align: "center" },
            y: { align: "center", dy: 30 }
        },

        tape: {
            x: { align: "center" },
            y: { align: "top", dy: 30 },
            rotation: [-75, -75, 0]
        }
    });

    cleverapps.overrideFonts(cleverapps.styles.FONTS, {
        COLLECTION_WINDOW_TEXT: {
            name: "default",
            size: 45,
            color: cleverapps.styles.COLORS.STICKER_LABEL_COLOR
        }
    });
}

if (cleverapps.styles.StickersBookJokerView) {
    cleverapps.overrideStyles(cleverapps.styles.StickersBookJokerView, {
        bg: {
            width: 135,
            height: 60,
            x: { align: "right", dx: -40 },
            y: { align: "center" }
        },
        text: {
            x: { align: "center", dx: -20 },
            y: { align: "center" }
        },
        withBillet: true
    });
}

if (cleverapps.styles.StickersCollectionIcon) {
    cleverapps.overrideStyles(cleverapps.styles.StickersCollectionIcon, {
        textBg: undefined,

        title: {
            maxWidth: 215,
            maxHeight: 42,
            x: { align: "center", dx: 0 },
            y: { align: "top", dy: -27 },
            unavailableOpacity: undefined
        },

        icon: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: -20 }
        },

        prizebar: {
            x: { align: "center" },
            y: { align: "center", dy: 20 },
            width: 160,
            checkmark: {
                x: { align: "center", dx: 0 },
                y: { align: "center", dy: 10 }
            },
            text: {
                dy: -1
            },
            button: {
                y: { align: "center", dy: 15 }
            }
        },
        margin: -10,
        availableText: {
            y: { align: "center", dy: 15 }
        },
        badge: {
            x: { align: "right", dx: 20 },
            y: { align: "top", dy: 10 }
        }
    });

    cleverapps.overrideFonts(cleverapps.styles.FONTS, {
        STICKERS_COLLECTION_TEXT: {
            size: 40,
            color: cleverapps.styles.COLORS.WHITE
        },
        COLLECTION_BADGE_TEXT: {
            size: 35
        },
        STICKERS_AVAILABLE_TEXT: {
            size: 40,
            color: cleverapps.styles.COLORS.STICKER_LABEL_COLOR
        }
    });
}

if (cleverapps.styles.StickersRewardWindow) {
    cleverapps.overrideStyles(cleverapps.styles.StickersRewardWindow, {
        stickers: {
            margin: {
                x: 80,
                y: 120
            }
        },
        source: {
            y: { align: "botom", dy: -50 }
        }
    });
}

if (cleverapps.styles.PrizeCalendarWindow) {
    cleverapps.overrideStyles(cleverapps.styles.PrizeCalendarWindow, {
        bg: {
            outerBg: {
                width: 764,
                height: 900,
                x: { align: "center" },
                y: { align: "center", dy: 8 }
            },
            decorPositions: [
                {
                    x: { align: "left", dx: 35 },
                    y: { align: "top", dy: -35 }
                },
                {
                    x: { align: "right", dx: -35 },
                    y: { align: "top", dy: -35 }
                },
                {
                    x: { align: "left", dx: 35 },
                    y: { align: "bottom", dy: 40 }
                },
                {
                    x: { align: "right", dx: -35 },
                    y: { align: "bottom", dy: 40 }
                }
            ],
            pattern: {
                x: { align: "center" },
                y: { align: "center", dy: 4 }
            }
        },
        margin: 0,
        windowPadding: { x: -18, y: -2, bottom: undefined },
        tiles: {
            margin: {
                x: 70, y: 60
            },
            padding: {
                x: 25,
                top: 0,
                bottom: 60
            }
        },
        ticketAnimation: {
            x: { align: "center", dx: 120 },
            y: { align: "center" },

            delay: {
                reward: 1,
                animation: 0.4
            },
            rewardNode: {
                x: { align: "center", dx: 130 },
                y: { align: "center" }
            }
        }
    });

    cleverapps.overrideStyles(cleverapps.styles.ActiveCalendarTileComponent, {
        titleBg: {
            width: 170,
            height: 50,
            x: { align: "center" },
            y: { align: "bottom", dy: -57 }
        },

        title: {
            x: { align: "center" },
            y: { align: "center", dy: -1 },
            width: 160,
            height: 50
        },

        adIcon: {
            x: { align: "right", dx: 20 },
            y: { align: "center" }
        }
    });
    cleverapps.overrideStyles(cleverapps.styles.PrizeCalendarTileView, {
        mark: {
            x: { align: "center" },
            y: { align: "center", dy: -3 }
        },
        rewards: {
            x: { align: "center" },
            y: { align: "center" },

            fitToBox: {
                width: 120,
                height: 120,

                maxScale: 1
            }
        }
    });

    cleverapps.overrideFonts(cleverapps.styles.FONTS, {
        PRIZE_CALENDAR_TITLE: {
            name: "default",
            size: 40
        }
    });
}

if (cleverapps.styles.BarrelAdsWindow) {
    cleverapps.overrideStyles(cleverapps.styles.BarrelAdsWindow, {
        contentMargin: 35,

        item: {
            height: 555,
            buttonMargin: 25,

            strip: {
                x: { align: "center", dx: 7 },
                y: { align: "bottom", dy: 104 }
            },

            title: {
                x: { align: "center" },
                y: { align: "bottom", dy: 42 }
            },

            button: {
                height: 103
            }
        },

        window: {
            padding: {
                top: 34,
                bottom: 60
            }
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.NoAdsWindow, {
    width: 840,
    height: 880,

    note: {
        y: { align: "top", dy: 10 }
    },

    footnote: {
        underline: {
            y: { align: "bottom", dy: -5 }
        }
    },

    badge: {
        scale: 1.2,
        x: { align: "right", dx: 50 },
        y: { align: "top", dy: -100 }
    },

    bonusnote: {
        x: { align: "center" },
        y: { align: "top", dy: -195 }
    },

    gift: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -10 }
    },

    button: {
        y: { align: "bottom", dy: -15 }
    }
});

if (cleverapps.styles.ExpeditionPageView) {
    cleverapps.overrideStyles(cleverapps.styles.ExpeditionPageView, {
        height: 620,

        message: {
            height: 82,
            width: 220,

            x: { align: "center", dx: 46 },
            y: { align: "top", dy: -96 },

            text: {
                x: { align: "center", dx: -6 },
                width: 220,
                height: 70
            },

            icon: {
                width: 70,
                height: 70
            },

            iconBg: {
                x: { align: "left", dx: -94 }
            }
        },

        button: {
            y: { align: "bottom", dy: 27 }
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.PassRewardIcon, {
    icon: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -20 }
    },

    claimButton: {
        height: 70,
        y: { align: "bottom", dy: -85 }
    },

    text: {
        x: { align: "right", dx: 6 },
        y: { align: "bottom", dy: -17 }
    },

    attention: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: -80 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PassHeader, {
    decor: {
        x: { align: "center", dx: -650 },
        y: { align: "center", dy: 42 }
    },

    timer: {
        margin: 40,
        x: { align: "center" },
        y: { align: "center", dy: 30 }
    },

    amount: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -50 }
    },

    pack: {
        x: { align: "right", dx: -115 },
        y: { align: "center", dy: 40 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.ExpeditionPassHeader, {
    title: {
        x: { align: "center" },
        y: { align: "center", dy: 35 }
    },

    amount: {
        x: { align: "center" },
        y: { align: "center", dy: -30 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PassTicket, {
    height: 280,
    text: {
        width: 250,
        height: 70
    },
    icon: {
        height: 270
    },

    free: {
        text: 235,
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 12 },
        icon: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 0 }
        }
    },

    premium: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 18 },
        icon: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 10 }
        },
        margin: -5
    },

    button: {
        height: 70
    }
});

cleverapps.overrideStyles(cleverapps.styles.PassProgress, {
    paddingX: 125,
    tasks: {
        taskPoint: {
            numberDy: 2,
            completedNumberDy: 5
        },

        premiumReward: {
            y: { align: "center", dy: 216 }
        },

        reward: {
            y: { align: "center", dy: -107 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PassTickets, {
    free: {
        x: { align: "center", dx: -8 },
        y: { align: "center", dy: -160 }
    },

    premium: {
        x: { align: "center", dx: -5 },
        y: { align: "center", dy: 150 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.BuildPassWindow, {
    width: 1762,
    height: 832,

    header: {
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: -4 }
    },

    progress: {
        x: { align: "right", dx: 3 },
        y: { align: "center", dy: -103 }
    },

    tickets: {
        x: { align: "left", dx: -3 },
        y: { align: "center", dy: -99 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.ExpeditionPassWindow, {
    width: 1718,

    header: {
        x: { align: "center" },
        y: { align: "top", dy: 20 },
        zOrder: -1
    },

    progress: {
        x: { align: "right", dx: 0 },
        y: { align: "bottom", dy: -38 }
    },

    tickets: {
        x: { align: "left", dx: 0 },
        y: { align: "center", dy: -106 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PassLastChanceWindow, {
    rewards: {
        ribbon: {
            x: { align: "left", dx: -12 },
            y: { align: "top", dy: -12 }
        },

        text: {
            x: { align: "center", dx: -10 },
            y: { align: "center", dy: 18 },
            width: 300,
            height: 80,
            rotation: 0
        }
    }
});

if (cleverapps.styles.LanternStartComponent) {
    cleverapps.overrideStyles(cleverapps.styles.LanternStartComponent, {
        bg: {
            scale9: {
                width: 583,
                height: 280
            }
        },

        lanternIcon: {
            y: { align: "center" }
        },

        helpButton: {
            x: { align: "right", dx: -11 },
            y: { align: "top", dy: -11 },
            scale: 0.8
        },

        label: {
            width: 300,
            height: 150,
            x: { align: "center", dx: 100 },
            y: { align: "center", dy: 45 }
        },

        progressBar: {
            x: { align: "center", dx: 100 }
        }
    });
}

if (cleverapps.styles.BoostersBeforeComponent) {
    cleverapps.overrideStyles(cleverapps.styles.BoostersBeforeComponent, {
        lantern: {
            boostersMargin: 35,
            contentMargin: 15
        }
    });
}

if (cleverapps.styles.StickersShopItemView) {
    cleverapps.overrideStyles(cleverapps.styles.StickersShopItemView, {
        size: [
            { width: 870, height: 420 },
            { width: 500, height: 890 },
            { width: 500, height: 890 }
        ],
        inner_bg: {
            size: [
                { width: 380, height: 380 },
                { width: 460, height: 350 },
                { width: 460, height: 350 }
            ],
            position: [
                {
                    x: { align: "left", dx: 20 },
                    y: { align: "center" }
                },
                {
                    x: { align: "center" },
                    y: { align: "top", dy: -20 }
                },
                {
                    x: { align: "center" },
                    y: { align: "top", dy: -20 }
                }
            ]
        },
        image: {
            position: [
                {
                    x: { align: "left", dx: 30 },
                    y: { align: "center", dy: -18 }
                },
                {
                    x: { align: "center", dx: 5 },
                    y: { align: "center", dy: 250 }
                },
                {
                    x: { align: "center", dx: 15 },
                    y: { align: "center", dy: 253 }
                }
            ]
        },
        button: {
            size: [
                {
                    width: 270,
                    height: 100
                },
                {
                    width: 300,
                    height: 100
                },
                {
                    width: 300,
                    height: 100
                }
            ],
            position: [
                {
                    x: { align: "center", dx: 180 },
                    y: { align: "bottom", dy: 30 }
                },
                {
                    x: { align: "center" },
                    y: { align: "bottom", dy: 30 }
                },
                {
                    x: { align: "center" },
                    y: { align: "bottom", dy: 30 }
                }
            ]
        },
        ribbon: {
            size: [
                { width: 460, height: 110 },
                { width: 550, height: 100 },
                { width: 550, height: 100 }
            ],
            position: [
                {
                    x: { align: "left", dx: -10 },
                    y: { align: "top", dy: 35 }
                },
                {
                    x: { align: "center", dx: 12 },
                    y: { align: "top", dy: -376 }
                },
                {
                    x: { align: "center", dx: 12 },
                    y: { align: "top", dy: -376 }
                }
            ]
        },
        rewards: {
            position: [{
                x: { align: "right", dx: -10 },
                y: { align: "center", dy: 50 }
            }, {
                x: { align: "center", dx: 0 },
                y: { align: "center", dy: -177 }
            },
            {
                x: { align: "center", dx: 0 },
                y: { align: "center", dy: -177 }
            }

            ],
            box: [{
                width: 400,
                height: 220
            }, {
                width: 380,
                height: 320
            }, {
                width: 380,
                height: 320
            }
            ]
        }

    });

    cleverapps.overrideFonts(cleverapps.styles.FONTS, {
        STICKERS_SHOP_REWARD: {
            color: cleverapps.styles.COLORS.WHITE
        }
    });
}

if (cleverapps.styles.PackOfferComponent && cleverapps.styles.PackOfferComponent[Offers.TYPES.STICKERS_PACK]) {
    cleverapps.overrideStyles(cleverapps.styles.PackOfferComponent[Offers.TYPES.STICKERS_PACK], {
        pack: {
            offsetY: 420,
            badge: {
                x: { align: "right", dx: 40 },
                y: { align: "top", dy: 45 },
                rotation: 30
            }
        },
        tap: {
            x: { align: "center", dx: 10 },
            y: { align: "center", dy: -80 },
            rotation: 0,
            scaleX: 1
        }
    });
}

if (cleverapps.styles.OpenLetterButtonBooster) {
    cleverapps.overrideStyles(cleverapps.styles.OpenLetterButtonBooster, {
        size: [{ width: 130, height: 110 }, { width: 240, height: 100 }, { width: 240, height: 100 }],
        position: [
            { x: { align: "left", dx: 10 }, y: { align: "bottom", dy: 390 } },
            { x: { align: "right", dx: -10 }, y: { align: "bottom", dy: 500 } },
            { x: { align: "right", dx: -10 }, y: { align: "bottom", dy: 650 } }
        ],

        content: {
            margin: [-3, 5, 5],
            reversed: [false, true, true],
            direction: [cleverapps.UI.VERTICAL, cleverapps.UI.HORIZONTAL, cleverapps.UI.HORIZONTAL],
            layout: [{ x: { align: "center", dx: 3 }, y: { align: "center", dy: 25 } },
                { x: { align: "center", dx: 3 }, y: { align: "center", dy: 6 } },
                { x: { align: "center", dx: 3 }, y: { align: "center", dy: 6 } }],
            freeBlock: { textWidth: 100 }
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.RestoreProgressButton, {
    height: 100
});

if (cleverapps.styles.HintButtonBooster) {
    cleverapps.overrideStyles(cleverapps.styles.HintButtonBooster, {
        size: [{ width: 130, height: 110 }, { width: 240, height: 100 }, { width: 240, height: 100 }],
        position: [
            { x: { align: "right", dx: -10 }, y: { align: "bottom", dy: 390 } },
            { x: { align: "right", dx: -10 }, y: { align: "bottom", dy: 350 } },
            { x: { align: "right", dx: -10 }, y: { align: "bottom", dy: 500 } }
        ],

        content: {
            margin: [-3, 5, 5],
            reversed: [false, true, true],
            direction: [cleverapps.UI.VERTICAL, cleverapps.UI.HORIZONTAL, cleverapps.UI.HORIZONTAL],
            layout: [{ x: { align: "center", dx: 3 }, y: { align: "center", dy: 25 } },
                { x: { align: "center", dx: 3 }, y: { align: "center", dy: 6 } },
                { x: { align: "center", dx: 3 }, y: { align: "center", dy: 6 } }],
            freeBlock: { textWidth: 100 }
        }
    });
}

if (cleverapps.styles.CustomerWindowTitle) {
    cleverapps.overrideStyles(cleverapps.styles.CustomerWindowTitle, {
        mainTitle: {
            text: {
                width: 460
            }
        }
    });
}

if (cleverapps.styles.BonusLevelChest) {
    cleverapps.overrideStyles(cleverapps.styles.BonusLevelChest, {
        chest: {
            y: { align: "top", dy: 230 },

            vertical: {
                x: { align: "center", dx: 0 },
                y: { align: "top", dy: 170 }
            }
        },

        timer: {
            height: 50,
            y: { align: "top", dy: 20 },
            countdown: {
                x: { align: "center", dx: 10 },
                y: { align: "center", dy: 0 }
            },
            vertical: {
                y: { align: "top", dy: 10 }
            }
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.RowView, {
    width: 1060,
    height: 137,

    paddingX: 36,

    content: {
        height: 96,
        y: { align: "center", dy: 3 }
    },

    place: {
        withBadge: {
            width: 60
        },
        width: 50
    },

    name: {
        x: { align: "left" }
    },

    prize: {
        width: 110,
        verticalWidth: 110
    },

    score: {
        width: 160,
        verticalWidth: 130,
        height: 64
    }
});

cleverapps.overrideStyles(cleverapps.styles.TableView, {
    margin: -6
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    ROW_PLAYER_TEXT: {
        name: "nostroke",
        size: 45,
        color: new cc.Color(70, 113, 0, 255)
    },

    ROW_TEXT: {
        name: "nostroke",
        size: 45,
        color: new cc.Color(142, 77, 60, 255)
    },

    ROW_SCORE_TEXT: {
        size: 45,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

cleverapps.overrideStyles(cleverapps.styles.MissionWindow, {
    table: {
        innerPadding: 0
    },

    contentPadding: {
        bottom: 70
    }
});

cleverapps.overrideStyles(cleverapps.styles.UI.SafeArea, {
    Vertical: {
        left: 15,
        right: 15
    },

    Horizontal: {
        left: 15,
        right: 15
    }
});
